import { BreakpointsOptions } from '@mui/material';

export const breakpointOptions: BreakpointsOptions = {
  values: {
    xs: 600,
    sm: 900,
    md: 1200,
    lg: 1440,
    xl: 2000
  }
};
