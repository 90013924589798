import Axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import qs from 'qs';

import { getAuthUrl } from './auth';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_API,
  withCredentials: true
});

AXIOS_INSTANCE.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' });

AXIOS_INSTANCE.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    if (response) {
      if (response.status === 401) {
        window.location.href = getAuthUrl();
      } else if (response.status === 504) {
        return null;
      } else {
        return response;
      }
    }
  }
);

let cancelTokenSource = Axios.CancelToken.source();

export const cancelPendingRequests = () => {
  cancelTokenSource.cancel();
  cancelTokenSource = Axios.CancelToken.source();
};

const requestCounts: { [key: string]: { count: number; timer: NodeJS.Timeout | null } } = {};

const rateLimitKey = (config: AxiosRequestConfig) => {
  return config.url || '';
};

const rateLimitInterceptor = (config: InternalAxiosRequestConfig) => {
  const isTestingMode = process.env.CI || process.env.NODE_ENV === 'development' || process.env.LOCAL_DEV;

  if (isTestingMode) {
    return config;
  }

  const key = rateLimitKey(config);

  if (!requestCounts[key]) {
    requestCounts[key] = { count: 0, timer: null };
  }

  requestCounts[key].count++;

  if (requestCounts[key].count > 3) {
    return Promise.reject(new Axios.Cancel('Too many same endpoint requests'));
  }

  if (!requestCounts[key].timer) {
    requestCounts[key].timer = setTimeout(() => {
      requestCounts[key].count = 0;
      requestCounts[key].timer = null;
    }, 1000);
  }

  return config;
};

const addCustomHeaderInterceptor = (config: InternalAxiosRequestConfig) => {
  // eslint-disable-next-line no-param-reassign
  config.headers['x-deepchecks-origin'] = 'UI';

  return config;
};

// Apply request interceptors
AXIOS_INSTANCE.interceptors.request.use(addCustomHeaderInterceptor);
AXIOS_INSTANCE.interceptors.request.use(rateLimitInterceptor);

export const customInstance = async <T>(config: AxiosRequestConfig): Promise<T> => {
  const promise = await AXIOS_INSTANCE({ ...config, cancelToken: cancelTokenSource.token })
    .then(({ data }) => data)
    .catch(() => '' /* logger */);

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
